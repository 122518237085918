<template>
  <div class="body_bgi box_1 full bgs_full relative " ref="home_box">
    <div class="full jian_bian_bgc">
      <!-- header -->
      <div class="header_bgi2 w_full h_100 bgs_full ph_20 border_box relative ">
        <div class="fw_bold fs_30 text_right ls_10 absolute left_center top_20 color_fff font_zh cursor" @click="goToPage('/')">
          向北开放重要桥头堡大数据平台
        </div> 

        <!-- 天气、气温 -->
        <div class="w_30_p jian_bian_text_1 text_right absolute top_30 right_10">
          <!-- <AmapWeather /> -->
        </div>
      </div>

      <!-- body -->
      <div class="w_full-60 h_full-130 m_0_auto relative">
        <!-- 图表-1 -->
        <div class="w_49_p h_full p_10 absolute top_0 left_0 z_999">
          <div class="full border_radius_10 bgc_fbf9f8e0 p_10">
            <!-- Echart 图表 -->
            <div class="w_full h_full p_10 overflow_y_auto scroll_box">
              <div class="full">
                <div class="w_full-30 text_center text_1 fw_bold cursor fs_20 ls_2 color_000">
                  <span class="color_ce1126">中国</span>向全球出口总额 Top 10
                </div>
                <div class="w_full h_full-15">
                  <data-chart 
                    title=""
                    chartType="柱状图3"
                    color="rgb(39, 111, 244)"
                    :isShowTitle="true" 
                    :isShowUnit="true"
                    :isShowFilterIcon="false"
                    :isShowFullIcon="true"
                    :chartData="chartData1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 图表-2 -->
        <div class="w_49_p h_full p_10 absolute top_0 right_0 z_999">
          <div class="full border_radius_10 bgc_fbf9f8e0 p_10">
            <!-- Echart 图表 -->
            <div class="w_full h_full p_10 overflow_y_auto scroll_box">
              <div class="full">
                <div class="w_full-30 text_center text_1 fw_bold cursor fs_20 ls_2 color_000">
                  <span class="color_ce1126">中国</span>向全球进口总额 Top 10
                </div>
                <div class="w_full h_full-15">
                  <data-chart 
                    title=""
                    chartType="柱状图3"
                    :isShowTitle="true" 
                    :isShowUnit="true"
                    :isShowFilterIcon="false"
                    :isShowFullIcon="true"
                    :chartData="chartData2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AmapWeather from '@/components/weather/AmapWeather.vue';

import defMixin from '@/mixins/def';
import componentsMixin from '@/mixins/components';

import { Message } from 'element-ui'

export default {
  name: 'China',
  components: {
    AmapWeather,
  },
  mixins: [defMixin, componentsMixin],
  data() {
    return {
      chartData1: {},
      chartData2: {}
    }
  },
  async mounted() {
    this.intChartData()
  },
  destroyed() {

  },
  methods: {
    intChartData() {
      this.chartData1 = {
        unit: '美元',
        color: "rgb(244, 39, 87)",
        x: [
          '重油制品，不含生物柴油',
          '仅装有驱动电动机的主要用于载人的机动车 ',
          '集装箱（包括运输液体的集装箱） ',
          '其他货运船舶及客货兼运船舶  ',
          '仅装有点燃式活塞内燃发动机的其他机动车辆，1000ml＜排量≤1500ml',
          '轻油制品，不含生物柴油',
          '瓷制固定卫生设备',
          '除草剂、抗萌剂及植物生长调节剂',
          '仅装有点燃式活塞内燃发动机的其他机动车辆，1500ml＜排量≤3000ml',
          '涂漆或涂塑普通钢铁板材',
        ].reverse(),
        y: [
          { value: 36334787834, itemStyle: { color: '#0874EF' } },
          { value: 20089422390, itemStyle: { color: '#0874EF' } },
          { value: 14685163462, itemStyle: { color: '#0874EF' } },
          { value: 13286305490, itemStyle: { color: '#0874EF' } },
          { value: 12248908595, itemStyle: { color: '#0874EF' } },
          { value: 11712029596, itemStyle: { color: '#0874EF' } },
          { value: 9337570110, itemStyle: { color: '#0874EF' } },
          { value: 7815945966, itemStyle: { color: '#0874EF' } },
          { value: 6783940873, itemStyle: { color: '#0874EF' } },
          { value: 6605675952, itemStyle: { color: '#0874EF' } }
        ].reverse(),
      }

      this.chartData2 = {
        unit: '美元',
        color: '#0874EF',
        x: [
          '石油原油及从沥青矿物提取的原油',
          '处理器及控制器',
          '未烧结的铁矿砂及其精矿，焙烧黄铁矿除外',
          '存储器',
          '其他集成电路',
          '其他未锻造金，非货币用',
          '其他大豆，不论是否破碎',
          '铜矿砂及其精矿',
          '液化天然气 ',
          '仅装有点燃式活塞内燃发动机的其他机动车辆，1500ml＜排量≤3000ml'
        ].reverse(),
        y: [
          { value: 363953386591, itemStyle: { color: '#d21e42' } },
          { value: 205168014519, itemStyle: { color: '#d21e42' } },
          { value: 124061423086, itemStyle: { color: '#d21e42' } },
          { value: 101614524738, itemStyle: { color: '#d21e42' } },
          { value: 93903107141, itemStyle: { color: '#d21e42' } },
          { value: 75429510996, itemStyle: { color: '#d21e42' } },
          { value: 61277586879, itemStyle: { color: '#d21e42' } },
          { value: 56683119249, itemStyle: { color: '#d21e42' } },
          { value: 52259828975, itemStyle: { color: '#d21e42' } },
          { value: 37626764846, itemStyle: { color: '#d21e42' } }
        ].reverse(),
      }

      this.chartData1 = {...this.chartData1}
      this.chartData2 = {...this.chartData2}
    },

    
    // 跳转页面
    goToPage(path) {
      this.$router.push(path)
    }

  }
}
</script>

